import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import GroupDocuments from '../../Groups/GroupDocuments';
import Loading from '../../Shared/ResourceIndex/Loading';
import { TooltipContextProvider } from '@sw-sw/lib-ui';
import ProjectPermissionContext from '../../../contexts/ProjectPermissionContext';
import { useProjectBmpDocuments } from '../../../hooks/projectDetails';
import AppContext from '../../../contexts/AppContext';
import projectApi from '../../../utils/api/project';
import ProjectContext from '../../../contexts/ProjectContext';
import clientApi from '../../../utils/api/client';

export interface IBmpDetailsProps {
  groupId: number;
  projectId: number;
}
const BmpDetails: React.FC<IBmpDetailsProps> = ({ groupId, projectId }) => {
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const bmpDetailsQuery = useProjectBmpDocuments(projectId, groupId);
  const [docs, setDocs] = useState<any>({});
  const projectContext = useContext(ProjectContext)
  const [data, setData] = useState<any>([])
  const [clientData, setClientData] = useState([])
  const clientId = projectContext.project && projectContext.project.client_id

  const getDocuments = async () => {
    try {
      const response = await projectApi.getDocs(projectId, 'Fixed Details')
      
      setData(response)      
      const getData = response.documentTypes.filter((e: { name: string; }) => e.name === "Fixed Details Project")[0]
      const newArr = response.regulations.filter((e: { docTypeName: string; }) => e.docTypeName !== "Regulations")
            
      setDocs([...newArr, ...getData.documents]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getDocuments()
  }, [projectContext]);

  useEffect(() => {    
    const fetchClientData = async() => {      
      try {        
        await clientApi.getClientsDocTypes.forProjects(clientId)
        .then((res) => res.map(ele => ele.id))
        .then((res) => data && data.documentTypes.filter((ele: { id: number; }) => res.includes(ele.id)))
        .then(res => setClientData(res))
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

      fetchClientData()
  }, [projectContext, data])


  const auth = useContext(AppContext).get('user');

  if (!bmpDetailsQuery.data) {
    return <Loading what='BMP Details' />;
  }

  const refetchData = (file: any, scope: any, groupDocId?: any) => {
    console.log(file, scope);
    
    if(groupDocId === 0){      
      getDocuments()
    }
    else bmpDetailsQuery.refetch()
  }

  if (bmpDetailsQuery.data.length) {
    console.log(
      'projectPermissionContext.readOnly>',
      projectPermissionContext.readOnly,
    );

    return (
      <TooltipContextProvider>
        <GroupDocuments
          scope='bmp'
          documentGroups={bmpDetailsQuery.data}
          document_group_id={groupId}
          onDocumentUpload={refetchData}
          onDocumentDelete={refetchData}
          onDocumentEdit={refetchData}
          canRename={            
            auth.roleName === 'Regional Manager (External)'
            ? false
            : !projectPermissionContext.readOnly
          }
          readOnly={projectPermissionContext.readOnly}
          label={'Project Documents'}
          roleCanDelete={
            auth.roleName === 'Regional Manager (External)'
              ? false
              : !projectPermissionContext.readOnly
          }
          replace={
            auth.roleName === 'Regional Manager (External)'
              ? false
              : !projectPermissionContext.readOnly
          }
          fixedDetails={docs}
          bmpProject={true}
          clientData={clientData ? clientData : []}
          canEdit={true}
        />
      </TooltipContextProvider>
    );
  } else {
    if (auth.roleName === 'Public' || auth.roleName === 'Public No Image') {
      return <p>No control measures have been added to the active site map.</p>;
    }
    
    return (
      <>
        <TooltipContextProvider>
          <GroupDocuments
            scope='bmp'
            fixedDetails={docs}
            bmpProject={true}
            clientData={clientData ? clientData : []}
            onDocumentUpload={refetchData}
            roleCanDelete={
              auth.roleName === 'Regional Manager (External)'
                ? false
                : !projectPermissionContext.readOnly
            }
            onDocumentDelete={refetchData}
            onDocumentEdit={refetchData}
            label={'Project Documents'}
            canRename={            
              auth.roleName === 'Regional Manager (External)'
              ? false
              : !projectPermissionContext.readOnly
            }
              replace={
              auth.roleName === 'Regional Manager (External)'
                ? false
                : !projectPermissionContext.readOnly
            }
            canEdit={true}
            />
        </TooltipContextProvider>

        <p>
          No control measures have been added to the active site map.{' '}
          <Link to={`/projects/${projectId}/active-site-map`}>Click here</Link>{' '}
          to manage site map control measures.
        </p>
      </>
    );
  }
};

export default BmpDetails;
