/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { get, pick, isEqual, kebabCase } from 'lodash';
import userApi from '../../../utils/api/user';

import { Modal, TooltipContextProvider } from '@sw-sw/lib-ui';
import Form1 from '../Forms/UserStepOne';
import { useUserFormData } from '../../../hooks/userFormData';
import AppContext from '../../../contexts/AppContext';
import { AppDivisionContext } from '../../../contexts/AppDivisionContext';
import { FormContext, FormContextProvider } from '@sw-sw/lib-form';
import xhrService from '../../../utils/api';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import { Redirect, useHistory } from 'react-router';
import { cloneDeep } from 'lodash';
import Permissions from './Permissions';
import { LoadingIcon } from '@sw-sw/lib-ui';
import TenantUserTab from './TenantUserTab';
import NavTabs from '../../Shared/NavTabs';
import { ActionButtons } from '../../Shared/ActionButtons';
import AddUserModal from '../AddUser/AddUserModal';

export const EditUserPage = ({ formOpts, showEditRole, isEdit }) => {
  const [userData, setUserData] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [projectList, setProjectList] = useState([])
  const [showDeleteTenantModal, setShowDeleteTenantModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [initialValue, setInitialValue] = useState({
    tags: [],
  });

  const appStore = useContext(AppContext);
  const appDivisionContext = useContext(AppDivisionContext);
  const { getPath } = useContext(AppDivisionContext);
  const formDataQuery = useUserFormData(appDivisionContext.appDivisionId);
  const [divisionId, setDivisionId] = useState([]);

  const [loader, setLoader] = useState(false);
  const [showPermissions, setShowPermissions] = useState(false);
  const [showAddTenantModal, setShowAddTenantModal] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const [refetch, setRefetch] = useState(false);
  const [tabs, setTabs] = useState(null);
  const secondaryActions = useRef([]);

  const [multiTenantData, setMultiTenantData] = useState({});

  const [errMsg, setErrMsg] = useState('');

  let history = useHistory();

  useEffect(() => {
    if (!formDataQuery.loading) {
      setFormData({
        roles: formDataQuery.roles.data,
        divisions: formDataQuery.divisions.data,
      });
    }
  }, [formDataQuery.loading]);

  const extractInitialValue = (user) => {
    const keys = [
      'id',
      'first_name',
      'last_name',
      'email',
      'address.phone',
      'address.phone_ext',
      'position',
      // 'roleId',
      // 'roleName',
      'ccrNumber',
      'peNumber',
    ];

    const mapAsOpts = (list) =>
      list.map((x) =>
        pick(x, ['id', 'name', 'client_id', 'divisionProject.division_id']),
      );

    return {
      ...pick(user, keys),

      /**
       * @important this is needed to initialize current selections
       */
      // clients: mapAsOpts(get(user, 'clients', [])),
      // projects: mapAsOpts(get(user, 'projects', [])),
      regulations: mapAsOpts(get(user, 'document_groups', [])),
      templates: mapAsOpts(get(user, 'templates', [])),
      // divisionIds: mapAsOpts(get(user, 'divisions', [])),
      // managerUserId:
      //   user.managers && Array.isArray(user.managers) && user.managers.length
      //     ? user.managers[0].id
      //     : null,
    };
  };

  // const getClientData = () => {
  //   return initialValue.clients.map((ele) => ({
  //     id: ele.id,
  //     name: ele.name,
  //   }));
  // };

  const CTA = ({ showDeleteTenant }) => {
    const formContext = useContext(FormContext);

    if (
      formContext.value.divisionIds &&
      formContext.value.divisionIds.length !== divisionId.length
    ) {
      setDivisionId(formContext.value.divisionIds);
    }

    const handleBack = () => {
      const obj = cloneDeep(formContext.value);

      delete obj.tags;
      if (isEqual(formContext.initialValue, obj)) {
        setRedirect(true);
      } else {
        setShowAlert(true);
      }
    };

    const handleCreateSubmit = () => {
      setLoader(true);

      tabs.forEach((tab) => {
        if (!multiTenantData[tab.id]) {
          multiTenantData[tab.id] = {};
        }
      });

      userApi
        .update(userId, {
          ...formContext.value,
          multiTenantData,
        })
        .then((res) => {
          appStore.triggerSuccessNotification();
          setLoader(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          console.log('err', err);
          setLoader(false);
        });
    };

    return (
      <div className='cta-outer-wrapper'>
        <div className='cta-inner-wrapper'>
          <button className='primary-outline' onClick={handleBack}>
            Back
          </button>
          <button className='primary' onClick={() => handleCreateSubmit()}>
            Save
          </button>

          {showDeleteTenant && (
            <button
              className='warn reversed'
              onClick={() => {
                setErrMsg('');
                setShowDeleteTenantModal(true);
              }}
            >
              Delete Tenant
            </button>
          )}
          {loader && <LoadingIcon />}
        </div>
      </div>
    );
  };

  const [formData, setFormData] = useState({
    roles: formDataQuery.roles.data,
    divisions: formDataQuery.divisions.data,
  });

  useEffect(async () => {
    const userIdArr = window.location.pathname.split('/');

    secondaryActions.current.push({
      icon: 'gears',
      label: 'Permissions',
      onClick: () => setShowPermissions(true),
    });

    if (
      ['Admin', 'Super Admin', 'Client Manager'].includes(appStore.get("user").roleName) &&
      Number(appStore.get("user").id) !== Number(userIdArr[4])
    ) {
      secondaryActions.current.push({
        icon: 'trash',
        label: 'Delete User',
        onClick: () => setShowDeleteModal(true),
      });
    }
    if (appStore.get("user").roleName === 'Super Admin') {
      secondaryActions.current.unshift({
        icon: 'plus',
        label: 'Add Tenant',
        onClick: () => setShowAddTenantModal(true),
      });
    }

    return () => (secondaryActions.current.length = 0);
  }, []);

  useEffect(() => {
    const userIdArr = window.location.pathname.split('/');

    setUserId(userIdArr[4]);
    userApi.get(userIdArr[4]).then(async (res) => {
      setUserData(res);
      let tabsData =
        res.tenants.map((tenant, index) => {
          var Tab = () => (
            <FormContextProvider key={tenant.id}>
              <TenantUserTab
                tenantId={tenant.id}
                userId={userIdArr[4]}
                multiTenantData={multiTenantData}
                setMultiTenantData={setMultiTenantData}
              />
            </FormContextProvider>
          );

          if (!Tab) {
            Tab = () => <p>no tab</p>;
          }

          const urlPrefix = `/`;

          return {
            Component: Tab,
            label: tenant.name,
            to: `${urlPrefix}${kebabCase(tenant.name)}`,
            id: tenant.id,
            showTab: () => true,
          };
        }) || [];

      //move current tenant to first tab
      const currentTenant = tabsData.findIndex(
        (tab) => tab.id === res.userTenantRoles[0].tenant_id,
      );

      if (currentTenant !== -1) {
        const currentTabData = tabsData.splice(currentTenant, 1);

        tabsData.unshift(currentTabData[0]);
      }

      setTabs(tabsData);

      setInitialValue(extractInitialValue(res));
      setDivisionId(extractInitialValue(res).divisionIds);
      setRefetch(false);
    });
  }, [refetch]);

  return (
    <TooltipContextProvider>
      <FormContextProvider initialValue={initialValue}>
        {!formDataQuery.loading && formData.divisions.length && (
          <section>
            <div
              style={{
                height: '60px',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h3>Edit User</h3>
              {[
                'Admin',
                'Super Admin',
                'Client Manager',
                'Area Manager',
                'Area / Assistant Manager',
                'Inspector'
              ].includes(appStore.get("user").roleName) && (
                <ActionButtons secondary={secondaryActions.current} />
              )}

              {showPermissions && (
                <Permissions
                  userId={userId}
                  hideModal={() => setShowPermissions(false)}
                  notifications={userData.notifications}
                  refetch={() => setRefetch(true)}
                />
              )}
              {showAddTenantModal && (
                <AddUserModal
                  onSubmit={() => {
                    setShowAddTenantModal(false);
                    setRefetch(true);
                  }}
                  onClose={() => setShowAddTenantModal(false)}
                  isEdit={true}
                  assignedTenants={userData.tenants}
                />
              )}
            </div>
            <Form1
              className={'edit-user-form-section'}
              formOpts={formData}
              showEditRole={true}
              isEdit={true}
              isProfile={false}
              isMyProfile={false}
              adminTab
            />
            <ConfirmationModal
              title={`There are some unsaved changes would you like to proceed?`}
              show={showAlert}
              handleClose={() => setShowAlert(false)}
              handleConfirm={() => {
                setRedirect(true);
              }}
              buttonText='Continue'
            />
            {redirect && <Redirect to={getPath('/admin')} push />}

            {tabs && (
              <>
                <h3>Tenant</h3>
                <NavTabs tabs={tabs} onSelectTab={(id) => setCurrentTab(id)} />
              </>
            )}
            {tabs && <CTA showDeleteTenant={tabs.length > 1} />}
            <ConfirmationModal
              title={`Are you sure you want to delete ?`}
              show={showDeleteModal}
              handleClose={() => setShowDeleteModal(false)}
              handleConfirm={async () => {
                const getProjectList = await userApi.getProjectList(userId);

                if(getProjectList.length === 0){
                  return xhrService
                    .delete(`/api/users/delete/${userId}`)
                    .then(() => {
                      appStore.triggerSuccessNotification();
                      setShowDeleteModal(false);
                      setRedirect(true);
                    });
                } else {
                  setShowWarning(true)
                  setProjectList(getProjectList)
                  setShowDeleteModal(false);
                }
              }}
              buttonText='Delete user'
            />

            <ConfirmationModal
              title={`Are you sure you want to delete tenant for this user ?`}
              show={showDeleteTenantModal}
              handleClose={() => setShowDeleteTenantModal(false)}
              handleConfirm={async () => {
                const getProjectList = await userApi.getProjectListTenantWise(userId);

                if(getProjectList.length === 0){
                  return xhrService
                    .delete(
                      `/api/users/${userId}/deleteTenant/${tabs[currentTab].id}`,
                    )
                    .then(() => {
                      appStore.triggerSuccessNotification();

                      if (
                        userData.userTenantRoles[0].tenant_id ===
                        tabs[currentTab].id
                      ) {
                        setRedirect(true);
                      } else {
                        setRefetch(true);
                        history.push(tabs[0].to.split('/').pop());
                        setCurrentTab(0);
                        setShowDeleteTenantModal(false);
                      }
                    })
                    .catch((err) => {
                      console.log('err', err);
                      setErrMsg(err.response.data.message);
                    });
                } else {
                  setShowWarning(true)
                  setProjectList(getProjectList)
                  setShowDeleteTenantModal(false);
                }
              }}
              buttonText='Confirm'
              subTitle={errMsg}
            />
            {/* warning modal */}
            <Modal
              show={showWarning}
              title="Warning"
              subTitle={[
                `This user is already assigned as the inspector for following active projects.`,
              ]}
              submitBtnText="Close"
              handleClose={() => {
                setShowWarning(false);
                setProjectList([])
              }}
              handleSubmit={() => {
                setShowWarning(false);
                setProjectList([])
              }}
            >
              <div>
                <ol>
                  {projectList.map((project) => <li>{project.name}</li>)}
                </ol>
                <p 
                  style={{
                    color: "red",
                    fontStyle: "italic",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >Assign new inspector to these projects prior deactivating this user</p>
              </div>
            </Modal>
          </section>
        )}
      </FormContextProvider>
    </TooltipContextProvider>
  );
};
