import { useContext, useCallback, useEffect } from 'react';
import FormModal from '../../Shared/form/modal/FormModal';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import { FormContext, FormSchemaFields } from '@sw-sw/lib-form';
import { regionApi } from '../../../utils/api/region';
import divisionApi from '../../../utils/api/division';
import reportApi from '../../../utils/api/report';
import _ from 'lodash';
import React from 'react';

const getProjectOptions = (availableClients: any, selectedClients: any) => {
  availableClients.sort((a: any, b: any) => a.name.localeCompare(b.name));
  if (selectedClients) {
    const selectedClientIds = selectedClients.map((client: any) => client.id);
    const clients = availableClients.filter((ac: any) =>
      selectedClientIds.includes(ac.id),
    );

    return clients.reduce(
      (acc: any, client: any) => acc.concat(client.projects),
      [],
    );
  }

  return [];
};

const getSchema = (formData: any) => {
  const formContext = useContext(FormContext);

  const divisions = formData.divisions;
  const regions = formData.regions;

  const clients = formData.clients;

  const schema: any = {
    regionId: {
      controlType: UIControlType.select,
      openOnFocus: true,
      labelKey: 'name',
      valueKey: 'id',
      label: 'Select Region',
      options: regions,
      noOptionMessage: 'No Regions found',
      placeholder: 'Search for Regions',
      validation: {
        required: true,
      },
    },
    divisionIds: {
      controlType: UIControlType.customTagSelect,
      labelKey: 'name',
      valueKey: 'id',
      isMulti: true,
      openOnFocus: true,
      showSelectAll: true,
      label: 'Select Division(s)',
      options: divisions,
      noOptionMessage: 'No Divisions found',
      showClearAll: true,
      placeholder: 'Search for Divisions',
      validation: {
        required: true,
      },
    },

    clients: {
      controlType: UIControlType.customTagSelect,
      labelKey: 'name',
      valueKey: 'id',
      isMulti: true,
      openOnFocus: true,
      label: 'Select Client(s)',
      options: clients,
      noOptionMessage: 'No clients found',
      placeholder: 'Search for clients',
      showSelectAll: true,
      showClearAll: true,
      validation: {
        required: true,
      },
    },
    projects: {
      controlType: UIControlType.customTagSelect,
      labelKey: 'name',
      valueKey: 'id',
      isMulti: true,
      openOnFocus: true,
      showSelectAll: true,
      label: 'Select Project(s)',
      options:
        (clients && getProjectOptions(clients, formContext.value.clients)) ||
        [],
      noOptionMessage: 'No projects found',
      placeholder: 'Search for projects',
      showClearAll: true,
      validation: {
        required: true,
      },
    },
    start_date: {
      controlType: UIControlType.date,
      label: 'Start Date',
      style: { flex: '0 1 50%' },
      validation: {
        maxDate: new Date(),
        required: true,
      },
    },
    end_date: {
      controlType: UIControlType.date,
      label: 'End Date',
      style: { flex: '0 1 50%' },
      validation: {
        minDate: formContext.value.start_date,
        maxDate: new Date(),
        required: true,
      },
    },
  };

  if (regions && regions.length === 0) {
    delete schema.regionId;
  }

  return schema;
};

const ProjectActivityLogReportModal = ({
  setShowModal,
  setData,
  setFormData,
  formData,
  isEdit,
}: {
  setShowModal: Function;
  setData: Function;
  setFormData: Function;
  formData: any;
  isEdit: boolean;
}) => {
  const formContext = useContext(FormContext);

  useEffect(() => {
    if (formContext.value.regionId && formContext.value.regionId.length) {
    }
    regionApi.index().then((data) => {
      setFormData({
        ...formData,
        regions: data.length ? [{ id: 0, name: 'Select all' }, ...data] : [],
      });
      formContext.set('regionId', '0');
    });
  }, []);

  useEffect(() => {
    if (formContext.value.regionId && formContext.value.regionId.length) {
      setDivisions(formContext.value.regionId);
    }
  }, [formContext.value.regionId]);

  useEffect(() => {
    if (formContext.value.divisionIds) {
      setClients();
    }
  }, [formContext.value.divisionIds]);

  const setDivisions = useCallback(
    (regionId) => {
      regionApi.divisions
        .index(regionId)
        .then((data) => {
          setFormData({
            ...formData,
            divisions: data.length ? data : [],
          });

          if (
            formContext.value.divisionIds &&
            formContext.value.divisionIds.length
          ) {
            formContext.set(
              'divisionIds',
              formContext.value.divisionIds.filter((c: any) =>
                data.map((_) => _.id).includes(c.id),
              ),
            );
          } else {
            formContext.set('divisionIds', []);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [formContext.value.regionId],
  );

  const setClients = useCallback(async () => {
    if (formContext.value.divisionIds && formContext.value.divisionIds.length) {
      const divisionIds = formContext.value.divisionIds.map((d: any) => d.id);
      const clients = await divisionApi.clients.getClientsForMultipleDivisions(
        divisionIds,
      );

      setFormData({
        ...formData,
        clients: clients.length ? clients : [],
      });

      if (formContext.value.clients && formContext.value.clients.length) {
        formContext.set(
          'clients',
          formContext.value.clients.filter((c: any) =>
            clients.map((_: any) => _.id).includes(c.id),
          ),
        );
      } else {
        formContext.set('clients', []);
      }
    }
    formContext.setBusy(false);
  }, [formContext.value.divisionIds]);

  const submit = async () => {
    const clientIds = formContext.value.clients.map((c: any) => c.id);
    const divisionIds = formContext.value.divisionIds.map((d: any) => d.id);
    const startDate = formContext.value.start_date;
    const endDate = formContext.value.end_date;
    
    const projectIds = formContext.value.projects.map((p: any) => p.id);

    reportApi
      .getProjectActivityLogs({
        clientIds,
        divisionIds,
        minDate: startDate,
        maxDate: endDate,
        divisionId: divisionIds[0],
        projectIds,
      })
      .then((data) => {
        const transData = data.map((d: any) => ({
          id: d.id,
          projectName: d.projectName,
          activityDate: d.activityDate,
          activityType: d.activityType,
          clientName: d.client.name,
          clientCode: d.clientCode,
          permitteeName: d.permitteeName,
          inspectionIntervalName: d.inspectionIntervalName,
          projectType: d.projectType,
          notes: d.notes,
          projectId: d.projectId,
        }));

        const grouped: any = _.groupBy(transData, 'projectId');

        for (const key in grouped) {
          if (grouped[key].length > 1) {
            for (const prop in grouped[key][0]) {
              if (grouped[key][0][prop] !== grouped[key][1][prop]) {
                const find: any = transData.find(
                  (item: any) => item.id === grouped[key][0].id,
                );

                if (find) {
                  if (find.changed) find.changed.push(prop);
                  else find.changed = [prop];
                }
              }
            }
          }
        }

        setData(transData);
        setShowModal(false);
      });

    return Promise.resolve();
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <FormModal
      onSubmit={submit}
      onCancel={handleClose}
      modalProps={{
        title: isEdit ? 'Edit Report' : 'Create Report',
        submitBtnText: 'Save',
      }}
    >
      <section className='project-form'>
        <section className='container'>
          <FormSchemaFields
            schema={getSchema(formData)}
            onChange={formContext.set}
            formData={formContext.value}
            initialFormData={formContext.value}
          />
        </section>
      </section>
    </FormModal>
  );
};

export default ProjectActivityLogReportModal;
